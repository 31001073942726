import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { useSearchParams } from "src/hooks/use-search-params";
import axios from "axios";

export const GuestGuard = (props) => {
  const { children } = props;
  const { user, isAuthenticated } = useAuth();
  const router = useRouter();
  const searchParams = useSearchParams();
  const returnTo = searchParams.get("returnTo");
  const [checked, setChecked] = useState(false);

  const verifyHashAndRedirect = async () => {
    // Verify if the customer already has a pending request (ONLY ONE) and get the id from there
    const contactId = user.contact.id;

    const deployEnvironment = require("../deployEnvironment.js");
    const API = deployEnvironment;
    const accessToken = sessionStorage.getItem("accessToken");

    const response = await axios.post(
      `${API}/api/getAssociationRequestsOfContact`,
      {
        contactId,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const associationRequest = response.data[0];

    let generatedHash;

    if (associationRequest) {
      generatedHash = associationRequest.temporaryId;
    } else {
      generatedHash = Array.from({ length: 16 }, () =>
        "0123456789abcdefghijklmnopqrstuvwxyz".charAt(
          Math.floor(Math.random() * 16)
        )
      ).join("");
    }

    window.location.href =
      returnTo || paths.customers.companyForm.index + `/${generatedHash}`;
  };

  const check = useCallback(() => {
    if (isAuthenticated) {
      // Check if user is customer and redirect it to its first available company
      if (user && user?.group?.groupName === "Customer") {
        const companies = user.contact?.companies;
        console.log(companies);
        if (companies.length && companies[0]?.companyId) {
          const firstCompanyId = companies[0]?.companyId;

          router.replace(
            paths.customers.dashboard.index + `/${firstCompanyId}`
          );
        } else {
          verifyHashAndRedirect();
        }
      } else {
        router.replace(paths.dashboard.index);
      }
    } else {
      setChecked(true);
    }
  }, [isAuthenticated, router]);

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(
    () => {
      check();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // not authenticated / authorized.

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};
